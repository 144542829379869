import Swiper from 'swiper/bundle';
import SmoothScroll from 'smooth-scroll';
import 'swiper/swiper-bundle.css';
import '@unocss/reset/tailwind.css'
import './style.css';

import '@fortawesome/fontawesome';
import '@fortawesome/fontawesome-free-solid';
import '@fortawesome/fontawesome-free-regular';
import '@fortawesome/fontawesome-free-brands';


window.addEventListener('DOMContentLoaded', () => {
  humBurgerMenuMake();
  initLessonProSwiper();
  voiceReviewSwiper();
  lessonFlowSwiper();
  questionToggle();
  scrollFadeIn();
  let scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300
  });
});

window.addEventListener('scroll', () => {
  imageLazyLoad();
}, false);

/* スクロール時にフェードイン */
function scrollFadeIn() {
  let fadeInTarget = document.querySelectorAll('.fade-in');
  window.addEventListener('scroll', () => {
    for (let i = 0; i < fadeInTarget.length; i++){
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.scrollY || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight; // 現在のブラウザの高さ
      if (scroll > offset - windowHeight + 150) {
        fadeInTarget[i].classList.add('scroll-in');
      }
    }
  })
}

/* レッスンプロsectionのスライドの設定 */
function initLessonProSwiper() {
  const lessonProSwiper = new Swiper('#lesson-pro-profile', {
    // 表示させるスライド枚数
    slidesPerView: 1,
    // 無限ループ設定
    loop: true,
    // スライドスピード(画像が切り替わる際のスピード))
    speed: 400,
    spaceBetween: 4, //スライド間の余白
    // 左右のボタンのクラス
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // 画像下のポチボタン
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    // レスポンシブ対応
    breakpoints: {
      780: {  // 画面サイズ750以下になった場合
        slidesPerView: 3, // スライド枚数を1枚に
        spaceBetween: 0
      }
    }
  });
}

/* お客様の声スライドの設定 */
function voiceReviewSwiper() {
  const voiceSwiper = new Swiper('#voice-review', {
    // 表示させるスライド枚数
    slidesPerView: 1,
    // 無限ループ設定
    loop: true,
    // スライドスピード(画像が切り替わる際のスピード))
    speed: 400,
    spaceBetween: 4, //スライド間の余白
    // 左右のボタンのクラス
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // 画像下のポチボタン
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    // レスポンシブ対応
    breakpoints: {
      780: {  // 画面サイズ750以下になった場合
        slidesPerView: 3, // スライド枚数を1枚に
        spaceBetween: 0
      }
    }
  });
}

function lessonFlowSwiper(){

  const flowSwiper = new Swiper('#lesson-flow-list', {
    // 表示させるスライド枚数
    slidesPerView: 1,
    // 無限ループ設定
    loop: true,
    // スライドスピード(画像が切り替わる際のスピード))
    speed: 400,
    // Enable pagination
    pagination: {
      el: '.pagination-custom1',  // First pagination
      clickable: true,
      type: 'bullets',
    },
    // 左右のボタンのクラス
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // レスポンシブ対応
    breakpoints: {
      780: {  // 画面サイズ750以下になった場合
        slidesPerView: 3, // スライド枚数を1枚に
        spaceBetween: 0
      }
    },
    on: {
      init: function() {
        this.pagination.render();
        this.pagination.el.classList.add('pagination-custom2');
        syncPagination(this);
      },
      slideChange : function() {
        console.log('Slide changed to: ', this.realIndex);
        syncPagination(this);
      }
    }
  });
  
}

  // Function to sync paginations
  function syncPagination(swiper) {
    const paginationCustom1 = document.querySelector('.pagination-custom1');
    const paginationCustom2 = document.querySelector('.pagination-custom2');

    // Sync pagination content
    paginationCustom2.innerHTML = paginationCustom1.innerHTML;

    // Update active state for second pagination
    const bullets1 = paginationCustom1.querySelectorAll('.swiper-pagination-bullet');
    const bullets2 = paginationCustom2.querySelectorAll('.swiper-pagination-bullet');
    bullets1.forEach((bullet, index) => {
      if (index === swiper.realIndex) {
        bullet.classList.add('swiper-pagination-bullet-active');
      } else {
        bullet.classList.remove('swiper-pagination-bullet-active');
      }
    });
    bullets2.forEach((bullet, index) => {
      if (index === swiper.realIndex) {
        bullet.classList.add('swiper-pagination-bullet-active');
      } else {
        bullet.classList.remove('swiper-pagination-bullet-active');
      }
    });

    // Make second pagination clickable
    bullets2.forEach((bullet, index) => {
      bullet.addEventListener('click', () => {
        console.log('Bullet clicked: ', index);
        swiper.slideToLoop(index); // Use slideToLoop for looped swiper
      });
    });
  }

/* ハンバーガーメニュー */
function humBurgerMenuMake() {
  document.querySelector('.hamburger').addEventListener('click', function(){
    this.classList.toggle('active');
    document.querySelector('.slide-menu').classList.toggle('active');
  })
}

/* よくある質問のトグル */
function questionToggle() {
  const questionList = document.querySelectorAll('.question');
  for (let i = 0; i < questionList.length; i++) {
    questionList[i].addEventListener('click', ()=> {
      questionList[i].classList.toggle('active');
    });
  }
}
